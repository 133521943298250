/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getCustomer = /* GraphQL */ `
  query GetCustomer($id: ID!) {
    getCustomer(id: $id) {
      id
      fname
      lname
      address
      phoneNumber
      emailAddress
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listCustomers = /* GraphQL */ `
  query ListCustomers(
    $filter: ModelCustomerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCustomers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        fname
        lname
        address
        phoneNumber
        emailAddress
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getEmployee = /* GraphQL */ `
  query GetEmployee($id: ID!) {
    getEmployee(id: $id) {
      id
      firstName
      lastName
      phoneNumber
      emailAddress
      token
      location
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listEmployees = /* GraphQL */ `
  query ListEmployees(
    $filter: ModelEmployeeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEmployees(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        firstName
        lastName
        phoneNumber
        emailAddress
        token
        location
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getVehicle = /* GraphQL */ `
  query GetVehicle($id: ID!) {
    getVehicle(id: $id) {
      id
      make
      model
      color
      tagNumber
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listVehicles = /* GraphQL */ `
  query ListVehicles(
    $filter: ModelVehicleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listVehicles(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        make
        model
        color
        tagNumber
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getTrip = /* GraphQL */ `
  query GetTrip($id: ID!) {
    getTrip(id: $id) {
      id
      fname
      lname
      address
      address2
      phoneNumber
      wheelchair
      roundtrip
      appointmentTime
      appointmentDate
      status
      cost
      driver
      broker
      notes
      distance
      trip
      afterHours
      pickupTime
      pickupStatus
      pickupStatusTime
      arrivedTime
      invoiceNumber
      employeePay
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listTrips = /* GraphQL */ `
  query ListTrips(
    $filter: ModelTripFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTrips(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        fname
        lname
        address
        address2
        phoneNumber
        wheelchair
        roundtrip
        appointmentTime
        appointmentDate
        status
        cost
        driver
        broker
        notes
        distance
        trip
        afterHours
        pickupTime
        pickupStatus
        pickupStatusTime
        arrivedTime
        invoiceNumber
        employeePay
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getBroker = /* GraphQL */ `
  query GetBroker($id: ID!) {
    getBroker(id: $id) {
      id
      name
      phone
      email
      wcRate
      ambRate
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listBrokers = /* GraphQL */ `
  query ListBrokers(
    $filter: ModelBrokerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBrokers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        phone
        email
        wcRate
        ambRate
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getVehicleCheck = /* GraphQL */ `
  query GetVehicleCheck($id: ID!) {
    getVehicleCheck(id: $id) {
      id
      myVehicle
      status
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listVehicleChecks = /* GraphQL */ `
  query ListVehicleChecks(
    $filter: ModelVehicleCheckFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listVehicleChecks(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        myVehicle
        status
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getInvoice = /* GraphQL */ `
  query GetInvoice($id: ID!) {
    getInvoice(id: $id) {
      id
      poNumber
      name
      broker
      date
      product
      cost
      distance
      address
      status
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listInvoices = /* GraphQL */ `
  query ListInvoices(
    $filter: ModelInvoiceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listInvoices(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        poNumber
        name
        broker
        date
        product
        cost
        distance
        address
        status
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
