/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-2",
    "aws_cloud_logic_custom": [
        {
            "name": "api32780395",
            "endpoint": "https://vsji3ei487.execute-api.us-east-2.amazonaws.com/dev",
            "region": "us-east-2"
        },
        {
            "name": "api77aadb89",
            "endpoint": "https://metmei56l0.execute-api.us-east-2.amazonaws.com/dev",
            "region": "us-east-2"
        },
        {
            "name": "myinvoice",
            "endpoint": "https://kjidspetdi.execute-api.us-east-2.amazonaws.com/dev",
            "region": "us-east-2"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://46toxrygmrgh3a6w4qrmwolvty.appsync-api.us-east-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-2",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-gtoprve44zatbf2nfkakzh5l6y",
    "aws_cognito_identity_pool_id": "us-east-2:07fd3199-df0f-4d91-98ec-2a33972a6e9c",
    "aws_cognito_region": "us-east-2",
    "aws_user_pools_id": "us-east-2_zO2ZFnc5c",
    "aws_user_pools_web_client_id": "4bmq5v8guf8tvute3tqqudkhtp",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "PHONE_NUMBER"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ]
};


export default awsmobile;
