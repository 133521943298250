

import React from "react";

import { MDBContainer } from 'mdb-react-ui-kit';

import { withAuthenticator } from '@aws-amplify/ui-react';


class Logged extends React.Component {


render() {






    return (


      <MDBContainer size="md">


      </MDBContainer>

      )
      }
    }
      export default Logged